export const Table = () => import('../../components/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const AccordionBlock = () => import('../../components/accordion-block/AccordionBlock.vue' /* webpackChunkName: "components/accordion-block" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/accordion-block/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const FilterAccordionBlock = () => import('../../components/accordion-block/FilterAccordionBlock.vue' /* webpackChunkName: "components/filter-accordion-block" */).then(c => wrapFunctional(c.default || c))
export const FilterAccordionItem = () => import('../../components/accordion-block/FilterAccordionItem.vue' /* webpackChunkName: "components/filter-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const ProductDescriptionAccordionItem = () => import('../../components/accordion-block/ProductDescriptionAccordionItem.vue' /* webpackChunkName: "components/product-description-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const AddToCartPopupDesktop = () => import('../../components/add-to-cart-popup/AddToCartPopupDesktop.vue' /* webpackChunkName: "components/add-to-cart-popup-desktop" */).then(c => wrapFunctional(c.default || c))
export const AddToCartPopupMobile = () => import('../../components/add-to-cart-popup/AddToCartPopupMobile.vue' /* webpackChunkName: "components/add-to-cart-popup-mobile" */).then(c => wrapFunctional(c.default || c))
export const AddToCartAlgolia = () => import('../../components/algolia/AddToCartAlgolia.vue' /* webpackChunkName: "components/add-to-cart-algolia" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaRecommendController = () => import('../../components/algolia/AlgoliaRecommendController.vue' /* webpackChunkName: "components/algolia-recommend-controller" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaResultsController = () => import('../../components/algolia/AlgoliaResultsController.vue' /* webpackChunkName: "components/algolia-results-controller" */).then(c => wrapFunctional(c.default || c))
export const PLPProductClickAlgolia = () => import('../../components/algolia/PLPProductClickAlgolia.vue' /* webpackChunkName: "components/p-l-p-product-click-algolia" */).then(c => wrapFunctional(c.default || c))
export const PurchaseAlgolia = () => import('../../components/algolia/PurchaseAlgolia.vue' /* webpackChunkName: "components/purchase-algolia" */).then(c => wrapFunctional(c.default || c))
export const BlogCollection = () => import('../../components/blog/BlogCollection.vue' /* webpackChunkName: "components/blog-collection" */).then(c => wrapFunctional(c.default || c))
export const BlogListItem = () => import('../../components/blog/BlogListItem.vue' /* webpackChunkName: "components/blog-list-item" */).then(c => wrapFunctional(c.default || c))
export const BlogPageInfo = () => import('../../components/blog/BlogPageInfo.vue' /* webpackChunkName: "components/blog-page-info" */).then(c => wrapFunctional(c.default || c))
export const BlogPagination = () => import('../../components/blog/BlogPagination.vue' /* webpackChunkName: "components/blog-pagination" */).then(c => wrapFunctional(c.default || c))
export const BlogTags = () => import('../../components/blog/BlogTags.vue' /* webpackChunkName: "components/blog-tags" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/breadcrumbs/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart-item/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartSidebar = () => import('../../components/cart-sidebar/CartSidebar.vue' /* webpackChunkName: "components/cart-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CentraCheckoutScript = () => import('../../components/checkout/CentraCheckoutScript.vue' /* webpackChunkName: "components/centra-checkout-script" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAddress = () => import('../../components/checkout/CheckoutAddress.vue' /* webpackChunkName: "components/checkout-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutContainer = () => import('../../components/checkout/CheckoutContainer.vue' /* webpackChunkName: "components/checkout-container" */).then(c => wrapFunctional(c.default || c))
export const CheckoutContent = () => import('../../components/checkout/CheckoutContent.vue' /* webpackChunkName: "components/checkout-content" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCountrySpecificText = () => import('../../components/checkout/CheckoutCountrySpecificText.vue' /* webpackChunkName: "components/checkout-country-specific-text" */).then(c => wrapFunctional(c.default || c))
export const CheckoutEmail = () => import('../../components/checkout/CheckoutEmail.vue' /* webpackChunkName: "components/checkout-email" */).then(c => wrapFunctional(c.default || c))
export const CheckoutErrors = () => import('../../components/checkout/CheckoutErrors.vue' /* webpackChunkName: "components/checkout-errors" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeader = () => import('../../components/checkout/CheckoutHeader.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c))
export const CheckoutMain = () => import('../../components/checkout/CheckoutMain.vue' /* webpackChunkName: "components/checkout-main" */).then(c => wrapFunctional(c.default || c))
export const CheckoutNewsletter = () => import('../../components/checkout/CheckoutNewsletter.vue' /* webpackChunkName: "components/checkout-newsletter" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentResult = () => import('../../components/checkout/CheckoutPaymentResult.vue' /* webpackChunkName: "components/checkout-payment-result" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSidebar = () => import('../../components/checkout/CheckoutSidebar.vue' /* webpackChunkName: "components/checkout-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSuccess = () => import('../../components/checkout/CheckoutSuccess.vue' /* webpackChunkName: "components/checkout-success" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTitle = () => import('../../components/checkout/CheckoutTitle.vue' /* webpackChunkName: "components/checkout-title" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTotals = () => import('../../components/checkout/CheckoutTotals.vue' /* webpackChunkName: "components/checkout-totals" */).then(c => wrapFunctional(c.default || c))
export const Collection = () => import('../../components/collection/Collection.vue' /* webpackChunkName: "components/collection" */).then(c => wrapFunctional(c.default || c))
export const CountrySelector = () => import('../../components/country-selector/CountrySelector.vue' /* webpackChunkName: "components/country-selector" */).then(c => wrapFunctional(c.default || c))
export const CountrySelectorIcon = () => import('../../components/country-selector/CountrySelectorIcon.vue' /* webpackChunkName: "components/country-selector-icon" */).then(c => wrapFunctional(c.default || c))
export const CountrySelectorPopup = () => import('../../components/country-selector/CountrySelectorPopup.vue' /* webpackChunkName: "components/country-selector-popup" */).then(c => wrapFunctional(c.default || c))
export const FlagIcon = () => import('../../components/country-selector/FlagIcon.vue' /* webpackChunkName: "components/flag-icon" */).then(c => wrapFunctional(c.default || c))
export const CustomActionController = () => import('../../components/custom-action/CustomActionController.vue' /* webpackChunkName: "components/custom-action-controller" */).then(c => wrapFunctional(c.default || c))
export const CustomSelect = () => import('../../components/custom-select/CustomSelect.vue' /* webpackChunkName: "components/custom-select" */).then(c => wrapFunctional(c.default || c))
export const Discount = () => import('../../components/discount/Discount.vue' /* webpackChunkName: "components/discount" */).then(c => wrapFunctional(c.default || c))
export const FacetInfoContainer = () => import('../../components/facet-info/FacetInfoContainer.vue' /* webpackChunkName: "components/facet-info-container" */).then(c => wrapFunctional(c.default || c))
export const FacetInfoContainerCollection = () => import('../../components/facet-info/FacetInfoContainerCollection.vue' /* webpackChunkName: "components/facet-info-container-collection" */).then(c => wrapFunctional(c.default || c))
export const FeatureToggle = () => import('../../components/feature-toggle/FeatureToggle.vue' /* webpackChunkName: "components/feature-toggle" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterMenu = () => import('../../components/footer/FooterMenu.vue' /* webpackChunkName: "components/footer-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterMenuRow = () => import('../../components/footer/FooterMenuRow.vue' /* webpackChunkName: "components/footer-menu-row" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/grid/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const GrowthbookExperimentContainer = () => import('../../components/growthbook/GrowthbookExperimentContainer.vue' /* webpackChunkName: "components/growthbook-experiment-container" */).then(c => wrapFunctional(c.default || c))
export const DesktopSubmenu = () => import('../../components/header/DesktopSubmenu.vue' /* webpackChunkName: "components/desktop-submenu" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const MainMenuSidebar = () => import('../../components/header/MainMenuSidebar.vue' /* webpackChunkName: "components/main-menu-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ToggleCart = () => import('../../components/header/ToggleCart.vue' /* webpackChunkName: "components/toggle-cart" */).then(c => wrapFunctional(c.default || c))
export const ToggleMenu = () => import('../../components/header/ToggleMenu.vue' /* webpackChunkName: "components/toggle-menu" */).then(c => wrapFunctional(c.default || c))
export const ToggleSearch = () => import('../../components/header/ToggleSearch.vue' /* webpackChunkName: "components/toggle-search" */).then(c => wrapFunctional(c.default || c))
export const ToggleWishlist = () => import('../../components/header/ToggleWishlist.vue' /* webpackChunkName: "components/toggle-wishlist" */).then(c => wrapFunctional(c.default || c))
export const Headline = () => import('../../components/headline/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/hero/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const HeroContent = () => import('../../components/hero/HeroContent.vue' /* webpackChunkName: "components/hero-content" */).then(c => wrapFunctional(c.default || c))
export const HeroController = () => import('../../components/hero/HeroController.vue' /* webpackChunkName: "components/hero-controller" */).then(c => wrapFunctional(c.default || c))
export const HeroCta = () => import('../../components/hero/HeroCta.vue' /* webpackChunkName: "components/hero-cta" */).then(c => wrapFunctional(c.default || c))
export const HeroReworked = () => import('../../components/hero/HeroReworked.vue' /* webpackChunkName: "components/hero-reworked" */).then(c => wrapFunctional(c.default || c))
export const ImageBlock = () => import('../../components/image-block/ImageBlock.vue' /* webpackChunkName: "components/image-block" */).then(c => wrapFunctional(c.default || c))
export const ImageCarusel = () => import('../../components/image-carusel/ImageCarusel.vue' /* webpackChunkName: "components/image-carusel" */).then(c => wrapFunctional(c.default || c))
export const ImageTextBlock = () => import('../../components/image-text-block/ImageTextBlock.vue' /* webpackChunkName: "components/image-text-block" */).then(c => wrapFunctional(c.default || c))
export const ImageTextBlockBlog = () => import('../../components/image-text-block/ImageTextBlockBlog.vue' /* webpackChunkName: "components/image-text-block-blog" */).then(c => wrapFunctional(c.default || c))
export const ImageTextBlockBlogWrapper = () => import('../../components/image-text-block/ImageTextBlockBlogWrapper.vue' /* webpackChunkName: "components/image-text-block-blog-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ImageTextBlockCatalogue = () => import('../../components/image-text-block/ImageTextBlockCatalogue.vue' /* webpackChunkName: "components/image-text-block-catalogue" */).then(c => wrapFunctional(c.default || c))
export const ImageTextBlockWrapper = () => import('../../components/image-text-block/ImageTextBlockWrapper.vue' /* webpackChunkName: "components/image-text-block-wrapper" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/input-text/InputText.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const LoadSpinner = () => import('../../components/load-spinner/LoadSpinner.vue' /* webpackChunkName: "components/load-spinner" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const MatchingProduct = () => import('../../components/matching-products/MatchingProduct.vue' /* webpackChunkName: "components/matching-product" */).then(c => wrapFunctional(c.default || c))
export const MatchingProducts = () => import('../../components/matching-products/MatchingProducts.vue' /* webpackChunkName: "components/matching-products" */).then(c => wrapFunctional(c.default || c))
export const MatchingSwimwearCartItem = () => import('../../components/matching-swimwear/Matching-SwimwearCartItem.vue' /* webpackChunkName: "components/matching-swimwear-cart-item" */).then(c => wrapFunctional(c.default || c))
export const MatchingSwimwearSizeSelectorPopup = () => import('../../components/matching-swimwear/Matching-SwimwearSizeSelectorPopup.vue' /* webpackChunkName: "components/matching-swimwear-size-selector-popup" */).then(c => wrapFunctional(c.default || c))
export const MatchingSwimwearProduct = () => import('../../components/matching-swimwear/MatchingSwimwearProduct.vue' /* webpackChunkName: "components/matching-swimwear-product" */).then(c => wrapFunctional(c.default || c))
export const MatchingSwimwearProducts = () => import('../../components/matching-swimwear/MatchingSwimwearProducts.vue' /* webpackChunkName: "components/matching-swimwear-products" */).then(c => wrapFunctional(c.default || c))
export const MpLink = () => import('../../components/mp-link/MpLink.vue' /* webpackChunkName: "components/mp-link" */).then(c => wrapFunctional(c.default || c))
export const Newsletter = () => import('../../components/newsletter/Newsletter.vue' /* webpackChunkName: "components/newsletter" */).then(c => wrapFunctional(c.default || c))
export const OrderTracking = () => import('../../components/order-tracking/OrderTracking.vue' /* webpackChunkName: "components/order-tracking" */).then(c => wrapFunctional(c.default || c))
export const Overlay = () => import('../../components/overlay/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c))
export const PaymentGatewayGridLogos = () => import('../../components/payment-gateway-logos/PaymentGatewayGridLogos.vue' /* webpackChunkName: "components/payment-gateway-grid-logos" */).then(c => wrapFunctional(c.default || c))
export const PaymentGatewayLogos = () => import('../../components/payment-gateway-logos/PaymentGatewayLogos.vue' /* webpackChunkName: "components/payment-gateway-logos" */).then(c => wrapFunctional(c.default || c))
export const PaypalButton = () => import('../../components/paypal-button/PaypalButton.vue' /* webpackChunkName: "components/paypal-button" */).then(c => wrapFunctional(c.default || c))
export const PaypalSdkButton = () => import('../../components/paypal-button/PaypalSdkButton.vue' /* webpackChunkName: "components/paypal-sdk-button" */).then(c => wrapFunctional(c.default || c))
export const Price = () => import('../../components/price/Price.vue' /* webpackChunkName: "components/price" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product-card/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardAlgolia = () => import('../../components/product-card/ProductCardAlgolia.vue' /* webpackChunkName: "components/product-card-algolia" */).then(c => wrapFunctional(c.default || c))
export const ProductCardCarousel = () => import('../../components/product-card/ProductCardCarousel.vue' /* webpackChunkName: "components/product-card-carousel" */).then(c => wrapFunctional(c.default || c))
export const ColorSwatches = () => import('../../components/product-details/ColorSwatches.vue' /* webpackChunkName: "components/color-swatches" */).then(c => wrapFunctional(c.default || c))
export const ProductDescription = () => import('../../components/product-details/ProductDescription.vue' /* webpackChunkName: "components/product-description" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/product-details/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product-details/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const AccordionFilterOption = () => import('../../components/product-filter/AccordionFilterOption.vue' /* webpackChunkName: "components/accordion-filter-option" */).then(c => wrapFunctional(c.default || c))
export const FilterOption = () => import('../../components/product-filter/FilterOption.vue' /* webpackChunkName: "components/filter-option" */).then(c => wrapFunctional(c.default || c))
export const ProductFilter = () => import('../../components/product-filter/ProductFilter.vue' /* webpackChunkName: "components/product-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductFilterAlgolia = () => import('../../components/product-filter/ProductFilterAlgolia.vue' /* webpackChunkName: "components/product-filter-algolia" */).then(c => wrapFunctional(c.default || c))
export const SelectedFilters = () => import('../../components/product-filter/SelectedFilters.vue' /* webpackChunkName: "components/selected-filters" */).then(c => wrapFunctional(c.default || c))
export const CentraProductList = () => import('../../components/product-list/CentraProductList.vue' /* webpackChunkName: "components/centra-product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../components/product-list/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductListAlgolia = () => import('../../components/product-list/ProductListAlgolia.vue' /* webpackChunkName: "components/product-list-algolia" */).then(c => wrapFunctional(c.default || c))
export const ProductListController = () => import('../../components/product-list/ProductListController.vue' /* webpackChunkName: "components/product-list-controller" */).then(c => wrapFunctional(c.default || c))
export const PulseLoader = () => import('../../components/pulse-loader/PulseLoader.vue' /* webpackChunkName: "components/pulse-loader" */).then(c => wrapFunctional(c.default || c))
export const PulseLoaderWithBackground = () => import('../../components/pulse-loader/PulseLoaderWithBackground.vue' /* webpackChunkName: "components/pulse-loader-with-background" */).then(c => wrapFunctional(c.default || c))
export const Question = () => import('../../components/questions/Question.vue' /* webpackChunkName: "components/question" */).then(c => wrapFunctional(c.default || c))
export const QuickShop = () => import('../../components/quick-shop/QuickShop.vue' /* webpackChunkName: "components/quick-shop" */).then(c => wrapFunctional(c.default || c))
export const QuickShopColorSwatches = () => import('../../components/quick-shop/QuickShopColorSwatches.vue' /* webpackChunkName: "components/quick-shop-color-swatches" */).then(c => wrapFunctional(c.default || c))
export const QuickShopTextContent = () => import('../../components/quick-shop/QuickShopTextContent.vue' /* webpackChunkName: "components/quick-shop-text-content" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveImage = () => import('../../components/responsive-image/ResponsiveImage.vue' /* webpackChunkName: "components/responsive-image" */).then(c => wrapFunctional(c.default || c))
export const RatingStars = () => import('../../components/reviews/RatingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(c => wrapFunctional(c.default || c))
export const RatingStarsInfoLeft = () => import('../../components/reviews/RatingStarsInfoLeft.vue' /* webpackChunkName: "components/rating-stars-info-left" */).then(c => wrapFunctional(c.default || c))
export const Review = () => import('../../components/reviews/Review.vue' /* webpackChunkName: "components/review" */).then(c => wrapFunctional(c.default || c))
export const TitleStars = () => import('../../components/reviews/TitleStars.vue' /* webpackChunkName: "components/title-stars" */).then(c => wrapFunctional(c.default || c))
export const RichTextSchema = () => import('../../components/rich-text-schema/RichTextSchema.vue' /* webpackChunkName: "components/rich-text-schema" */).then(c => wrapFunctional(c.default || c))
export const Scale = () => import('../../components/scale/Scale.vue' /* webpackChunkName: "components/scale" */).then(c => wrapFunctional(c.default || c))
export const SearchEmpty = () => import('../../components/search/SearchEmpty.vue' /* webpackChunkName: "components/search-empty" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/search/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/search/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const ShippingThreshold = () => import('../../components/shipping-threshold/ShippingThreshold.vue' /* webpackChunkName: "components/shipping-threshold" */).then(c => wrapFunctional(c.default || c))
export const SizeChartTable = () => import('../../components/size-guide/SizeChartTable.vue' /* webpackChunkName: "components/size-chart-table" */).then(c => wrapFunctional(c.default || c))
export const SizeGuide = () => import('../../components/size-guide/SizeGuide.vue' /* webpackChunkName: "components/size-guide" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideBottom = () => import('../../components/size-guide/SizeGuideBottom.vue' /* webpackChunkName: "components/size-guide-bottom" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideCalculator = () => import('../../components/size-guide/SizeGuideCalculator.vue' /* webpackChunkName: "components/size-guide-calculator" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideCamisoles = () => import('../../components/size-guide/SizeGuideCamisoles.vue' /* webpackChunkName: "components/size-guide-camisoles" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideStockings = () => import('../../components/size-guide/SizeGuideStockings.vue' /* webpackChunkName: "components/size-guide-stockings" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideTable = () => import('../../components/size-guide/SizeGuideTable.vue' /* webpackChunkName: "components/size-guide-table" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideTop = () => import('../../components/size-guide/SizeGuideTop.vue' /* webpackChunkName: "components/size-guide-top" */).then(c => wrapFunctional(c.default || c))
export const SizeSelectorDesktop = () => import('../../components/size-selector/SizeSelectorDesktop.vue' /* webpackChunkName: "components/size-selector-desktop" */).then(c => wrapFunctional(c.default || c))
export const SizeSelectorMobile = () => import('../../components/size-selector/SizeSelectorMobile.vue' /* webpackChunkName: "components/size-selector-mobile" */).then(c => wrapFunctional(c.default || c))
export const SizeSelectorPopup = () => import('../../components/size-selector/SizeSelectorPopup.vue' /* webpackChunkName: "components/size-selector-popup" */).then(c => wrapFunctional(c.default || c))
export const Slide = () => import('../../components/slide/Slide.vue' /* webpackChunkName: "components/slide" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const StripeWidget = () => import('../../components/stripe-widget/StripeWidget.vue' /* webpackChunkName: "components/stripe-widget" */).then(c => wrapFunctional(c.default || c))
export const TechnicalFeaturesAccordion = () => import('../../components/technical-features/TechnicalFeaturesAccordion.vue' /* webpackChunkName: "components/technical-features-accordion" */).then(c => wrapFunctional(c.default || c))
export const TextBlock = () => import('../../components/text-block/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => wrapFunctional(c.default || c))
export const Title = () => import('../../components/title/Title.vue' /* webpackChunkName: "components/title" */).then(c => wrapFunctional(c.default || c))
export const TopBanner = () => import('../../components/top-banner/TopBanner.vue' /* webpackChunkName: "components/top-banner" */).then(c => wrapFunctional(c.default || c))
export const TrustedShopsCarousel = () => import('../../components/trusted-shops-carousel/TrustedShopsCarousel.vue' /* webpackChunkName: "components/trusted-shops-carousel" */).then(c => wrapFunctional(c.default || c))
export const TrustpilotCarousel = () => import('../../components/trustpilot/TrustpilotCarousel.vue' /* webpackChunkName: "components/trustpilot-carousel" */).then(c => wrapFunctional(c.default || c))
export const TrustpilotProductReviews = () => import('../../components/trustpilot/TrustpilotProductReviews.vue' /* webpackChunkName: "components/trustpilot-product-reviews" */).then(c => wrapFunctional(c.default || c))
export const TrustpilotProductStarRating = () => import('../../components/trustpilot/TrustpilotProductStarRating.vue' /* webpackChunkName: "components/trustpilot-product-star-rating" */).then(c => wrapFunctional(c.default || c))
export const TrustpilotReviews = () => import('../../components/trustpilot/TrustpilotReviews.vue' /* webpackChunkName: "components/trustpilot-reviews" */).then(c => wrapFunctional(c.default || c))
export const TrustpilotStarRating = () => import('../../components/trustpilot/TrustpilotStarRating.vue' /* webpackChunkName: "components/trustpilot-star-rating" */).then(c => wrapFunctional(c.default || c))
export const TrustpilotWidget = () => import('../../components/trustpilot/TrustpilotWidget.vue' /* webpackChunkName: "components/trustpilot-widget" */).then(c => wrapFunctional(c.default || c))
export const TryggEhandel = () => import('../../components/trygg-ehandel/TryggEhandel.vue' /* webpackChunkName: "components/trygg-ehandel" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/video/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const YoutubeVideo = () => import('../../components/video/YoutubeVideo.vue' /* webpackChunkName: "components/youtube-video" */).then(c => wrapFunctional(c.default || c))
export const WashingInstructions = () => import('../../components/washing-instructions/WashingInstructions.vue' /* webpackChunkName: "components/washing-instructions" */).then(c => wrapFunctional(c.default || c))
export const WashingsWarranty = () => import('../../components/washings-warranty/WashingsWarranty.vue' /* webpackChunkName: "components/washings-warranty" */).then(c => wrapFunctional(c.default || c))
export const Wishlist = () => import('../../components/wishlist/Wishlist.vue' /* webpackChunkName: "components/wishlist" */).then(c => wrapFunctional(c.default || c))
export const WishlistIcon = () => import('../../components/wishlist/WishlistIcon.vue' /* webpackChunkName: "components/wishlist-icon" */).then(c => wrapFunctional(c.default || c))
export const YotpoController = () => import('../../components/yotpo/YotpoController.vue' /* webpackChunkName: "components/yotpo-controller" */).then(c => wrapFunctional(c.default || c))
export const YotpoPictureGallery = () => import('../../components/yotpo/YotpoPictureGallery.vue' /* webpackChunkName: "components/yotpo-picture-gallery" */).then(c => wrapFunctional(c.default || c))
export const YotpoCarousel = () => import('../../components/yotpo-testimonials/YotpoCarousel.vue' /* webpackChunkName: "components/yotpo-carousel" */).then(c => wrapFunctional(c.default || c))
export const YotpoSiteScore = () => import('../../components/yotpo-testimonials/YotpoSiteScore.vue' /* webpackChunkName: "components/yotpo-site-score" */).then(c => wrapFunctional(c.default || c))
export const YotpoTestimonials = () => import('../../components/yotpo-testimonials/YotpoTestimonials.vue' /* webpackChunkName: "components/yotpo-testimonials" */).then(c => wrapFunctional(c.default || c))
export const CartDiscounts = () => import('../../components/checkout/cart/CartDiscounts.vue' /* webpackChunkName: "components/cart-discounts" */).then(c => wrapFunctional(c.default || c))
export const CartEmpty = () => import('../../components/checkout/cart/CartEmpty.vue' /* webpackChunkName: "components/cart-empty" */).then(c => wrapFunctional(c.default || c))
export const CartItems = () => import('../../components/checkout/cart/CartItems.vue' /* webpackChunkName: "components/cart-items" */).then(c => wrapFunctional(c.default || c))
export const CartTotals = () => import('../../components/checkout/cart/CartTotals.vue' /* webpackChunkName: "components/cart-totals" */).then(c => wrapFunctional(c.default || c))
export const OrderDiscounts = () => import('../../components/checkout/order/OrderDiscounts.vue' /* webpackChunkName: "components/order-discounts" */).then(c => wrapFunctional(c.default || c))
export const OrderItem = () => import('../../components/checkout/order/OrderItem.vue' /* webpackChunkName: "components/order-item" */).then(c => wrapFunctional(c.default || c))
export const OrderTotals = () => import('../../components/checkout/order/OrderTotals.vue' /* webpackChunkName: "components/order-totals" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethod = () => import('../../components/checkout/payment/PaymentMethod.vue' /* webpackChunkName: "components/payment-method" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethods = () => import('../../components/checkout/payment/PaymentMethods.vue' /* webpackChunkName: "components/payment-methods" */).then(c => wrapFunctional(c.default || c))
export const PostCarrierIcon = () => import('../../components/checkout/shipping/PostCarrierIcon.vue' /* webpackChunkName: "components/post-carrier-icon" */).then(c => wrapFunctional(c.default || c))
export const ShippingMethod = () => import('../../components/checkout/shipping/ShippingMethod.vue' /* webpackChunkName: "components/shipping-method" */).then(c => wrapFunctional(c.default || c))
export const ShippingMethods = () => import('../../components/checkout/shipping/ShippingMethods.vue' /* webpackChunkName: "components/shipping-methods" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubAccount = () => import('../../components/comfort-club/comfort-club-account/ComfortClubAccount.vue' /* webpackChunkName: "components/comfort-club-account" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubAccountWelcome = () => import('../../components/comfort-club/comfort-club-account-welcome/ComfortClubAccountWelcome.vue' /* webpackChunkName: "components/comfort-club-account-welcome" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubCheckoutDropdown = () => import('../../components/comfort-club/comfort-club-checkout-dropdown/ComfortClubCheckoutDropdown.vue' /* webpackChunkName: "components/comfort-club-checkout-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubIndex = () => import('../../components/comfort-club/comfort-club-index/ComfortClubIndex.vue' /* webpackChunkName: "components/comfort-club-index" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubLogin = () => import('../../components/comfort-club/comfort-club-login/ComfortClubLogin.vue' /* webpackChunkName: "components/comfort-club-login" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubMenu = () => import('../../components/comfort-club/comfort-club-menu/ComfortClubMenu.vue' /* webpackChunkName: "components/comfort-club-menu" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubOrderList = () => import('../../components/comfort-club/comfort-club-orders/ComfortClubOrderList.vue' /* webpackChunkName: "components/comfort-club-order-list" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubOrderListItem = () => import('../../components/comfort-club/comfort-club-orders/ComfortClubOrderListItem.vue' /* webpackChunkName: "components/comfort-club-order-list-item" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubOrderListItemProducts = () => import('../../components/comfort-club/comfort-club-orders/ComfortClubOrderListItemProducts.vue' /* webpackChunkName: "components/comfort-club-order-list-item-products" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubRegister = () => import('../../components/comfort-club/comfort-club-register/ComfortClubRegister.vue' /* webpackChunkName: "components/comfort-club-register" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubResetPassword = () => import('../../components/comfort-club/comfort-club-reset-password/ComfortClubResetPassword.vue' /* webpackChunkName: "components/comfort-club-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubResetPasswordVerify = () => import('../../components/comfort-club/comfort-club-reset-password/ComfortClubResetPasswordVerify.vue' /* webpackChunkName: "components/comfort-club-reset-password-verify" */).then(c => wrapFunctional(c.default || c))
export const CarouselControls = () => import('../../components/product-list/ProductListCarousel/CarouselControls.vue' /* webpackChunkName: "components/carousel-controls" */).then(c => wrapFunctional(c.default || c))
export const CarouselIndicators = () => import('../../components/product-list/ProductListCarousel/CarouselIndicators.vue' /* webpackChunkName: "components/carousel-indicators" */).then(c => wrapFunctional(c.default || c))
export const ProductListCarousel = () => import('../../components/product-list/ProductListCarousel/ProductListCarousel.vue' /* webpackChunkName: "components/product-list-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductListCategoriesCollection = () => import('../../components/product-list/categories-collection/ProductListCategoriesCollection.vue' /* webpackChunkName: "components/product-list-categories-collection" */).then(c => wrapFunctional(c.default || c))
export const QuestionsSidebar = () => import('../../components/questions/questions-sidebar/QuestionsSidebar.vue' /* webpackChunkName: "components/questions-sidebar" */).then(c => wrapFunctional(c.default || c))
export const QuestionsViewer = () => import('../../components/questions/questions-sidebar/QuestionsViewer.vue' /* webpackChunkName: "components/questions-viewer" */).then(c => wrapFunctional(c.default || c))
export const QuestionsWriter = () => import('../../components/questions/questions-sidebar/QuestionsWriter.vue' /* webpackChunkName: "components/questions-writer" */).then(c => wrapFunctional(c.default || c))
export const InfoLeft = () => import('../../components/reviews/reviews-container/InfoLeft.vue' /* webpackChunkName: "components/info-left" */).then(c => wrapFunctional(c.default || c))
export const InfoRight = () => import('../../components/reviews/reviews-container/InfoRight.vue' /* webpackChunkName: "components/info-right" */).then(c => wrapFunctional(c.default || c))
export const ReviewsContainer = () => import('../../components/reviews/reviews-container/ReviewsContainer.vue' /* webpackChunkName: "components/reviews-container" */).then(c => wrapFunctional(c.default || c))
export const ReviewsContainerEmpty = () => import('../../components/reviews/reviews-container/ReviewsContainerEmpty.vue' /* webpackChunkName: "components/reviews-container-empty" */).then(c => wrapFunctional(c.default || c))
export const ReviewsSidebar = () => import('../../components/reviews/reviews-sidebar/ReviewsSidebar.vue' /* webpackChunkName: "components/reviews-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ReviewsViewer = () => import('../../components/reviews/reviews-sidebar/ReviewsViewer.vue' /* webpackChunkName: "components/reviews-viewer" */).then(c => wrapFunctional(c.default || c))
export const ReviewsWriter = () => import('../../components/reviews/reviews-sidebar/ReviewsWriter.vue' /* webpackChunkName: "components/reviews-writer" */).then(c => wrapFunctional(c.default || c))
export const InfoCenter = () => import('../../components/reviews/store-reviews-container/InfoCenter.vue' /* webpackChunkName: "components/info-center" */).then(c => wrapFunctional(c.default || c))
export const StoreReviewsContainer = () => import('../../components/reviews/store-reviews-container/StoreReviewsContainer.vue' /* webpackChunkName: "components/store-reviews-container" */).then(c => wrapFunctional(c.default || c))
export const AdyenCheckout = () => import('../../components/checkout/payment/methods/AdyenCheckout.vue' /* webpackChunkName: "components/adyen-checkout" */).then(c => wrapFunctional(c.default || c))
export const AdyenScript = () => import('../../components/checkout/payment/methods/AdyenScript.vue' /* webpackChunkName: "components/adyen-script" */).then(c => wrapFunctional(c.default || c))
export const KlarnaCheckout = () => import('../../components/checkout/payment/methods/KlarnaCheckout.vue' /* webpackChunkName: "components/klarna-checkout" */).then(c => wrapFunctional(c.default || c))
export const KlarnaScript = () => import('../../components/checkout/payment/methods/KlarnaScript.vue' /* webpackChunkName: "components/klarna-script" */).then(c => wrapFunctional(c.default || c))
export const KlarnaSnippet = () => import('../../components/checkout/payment/methods/KlarnaSnippet.vue' /* webpackChunkName: "components/klarna-snippet" */).then(c => wrapFunctional(c.default || c))
export const PaypalCheckout = () => import('../../components/checkout/payment/methods/PaypalCheckout.vue' /* webpackChunkName: "components/paypal-checkout" */).then(c => wrapFunctional(c.default || c))
export const PaypalScript = () => import('../../components/checkout/payment/methods/PaypalScript.vue' /* webpackChunkName: "components/paypal-script" */).then(c => wrapFunctional(c.default || c))
export const StripeScript = () => import('../../components/checkout/payment/methods/StripeScript.vue' /* webpackChunkName: "components/stripe-script" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubOrder = () => import('../../components/comfort-club/comfort-club-orders/comfort-club-order-details/ComfortClubOrder.vue' /* webpackChunkName: "components/comfort-club-order" */).then(c => wrapFunctional(c.default || c))
export const ComfortClubOrderProduct = () => import('../../components/comfort-club/comfort-club-orders/comfort-club-order-details/ComfortClubOrderProduct.vue' /* webpackChunkName: "components/comfort-club-order-product" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
