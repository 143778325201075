import algoliaSearch, { SearchIndex } from 'algoliasearch/lite'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import md5 from 'md5'
import type { Route } from 'vue-router'
import escape from 'lodash.escape'
import deepEquals from 'fast-deep-equal'
import { CentraProduct } from '@made-people/centra-models'
import { SearchResponse } from '@algolia/client-search'
import { AuthMode } from '@algolia/client-common'
import { RootState, AlgoliaProductFilterTab, AlgoliaState, FacetFilter } from './types'
import { getSizechartStandard, formatFacetFilterToQuery, formatFacetFilterFromQuery, getWhitelistedFilterFacetNames } from '~/lib/algolia-index-mapping'
import { ALGOLIA_KEYS, ALGOLIA_KEY_MAIN, ALGOLIA_KEY_SEARCH_RESULTS } from '@/utils/algoliaListKeys'
import { AlgoliaProduct } from '@/types/algolia'

export function state () {
  const state: RootState['algolia'] = {
    settings: null,
    filterOpen: false,
    selectedTab: undefined,
    enableDiscountFilter: false,
    ruleConditions: {},
    canonicalFacetFilters: undefined,
    lists: {}
  }

  return state
}

export const getters: GetterTree<RootState['algolia'], RootState> = {
  currentConsumer: state => (id: string) => state.lists[id]?.currentConsumer ?? false,
  hiddenFilters: state => state.settings?.content?.hiddenFilters ?? [],
  filterOpen: state => state.filterOpen,
  selectedTab: state => state.selectedTab,
  searchQuery: state => (id: string) => state.lists[id]?.searchQuery ?? '',
  initialFacets: state => (id: string) => state.lists[id]?.initialFacets ?? {},
  facets: state => (id: string) => state.lists[id]?.facets,
  initialFacetFilters: state => (id: string) => state.lists[id]?.initialFacetFilters ?? [],
  facetFilters: state => (id: string) => state.lists[id]?.facetFilters ?? [],
  products: state => (id: string) => state.lists[id]?.products,
  queryID: state => (id: string) => state.lists[id]?.queryID,
  limit: state => (id: string) => state.lists[id]?.limit,
  offset: state => (id: string) => state.lists[id]?.offset,
  pageSize: state => (id: string) => state.lists[id]?.pageSize,
  viewedProducts: (_, getters) => (id: string) => Math.min(getters.totalProducts(id), getters.limit(id)),
  totalProducts: state => (id: string) => state.lists[id]?.totalProducts,
  productsFrom: state => (id: string) => state.lists[id]?.productsFrom,
  algoliaMerchandisingRule: state => (id: string) => state.lists[id]?.algoliaMerchandisingRule,
  requestAnId (state) {
    const keys = Object.keys(state.lists).sort().map(v => parseInt(v)).filter(v => !isNaN(v))
    let smallestIndex = 0
    for (const key of keys) {
      if (key > smallestIndex) {
        return smallestIndex
      } else {
        ++smallestIndex
      }
    }
    return keys.length
  },
  sizechartStandard (_state, _getters, _rootState, rootGetters) {
    const currentCountryCode: string = rootGetters['frontend/currentCountryCode']
    if (!currentCountryCode) {
      return undefined
    }

    return getSizechartStandard(currentCountryCode.toLowerCase()) as string
  },
  isInLists: state => (id: string) => id in state.lists && !!state.lists[id],
  listenToQueryParams: state => (id: string) => state.lists[id]?.listenToQueryParams ?? false,
  oldQuery: state => (id: string) => state.lists[id]?.oldQuery ?? '',
  consumerWasDestroyed: state => (id: string) => state.lists[id]?.consumerWasDestroyed,
  ruleConditions: state => state.ruleConditions ?? {},
  canonicalFacetFilters: state => state.canonicalFacetFilters,
}

export const mutations: MutationTree<RootState['algolia']> = {
  setSettings (state, settings: any) {
    state.settings = settings
  },
  closeFilter (state) {
    state.filterOpen = false
    state.selectedTab = undefined
  },
  selectedTab (state, tabName?: AlgoliaProductFilterTab) {
    state.filterOpen = true
    state.selectedTab = tabName || 'size'
  },
  initialFacets (state, { id, value }: { id: string, value: AlgoliaState['lists']['']['initialFacets']}) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].initialFacets = value
  },
  resetInitialFacets (state, id: string) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].initialFacets = undefined
  },
  facets (state, { id, value }: { id: string, value: AlgoliaState['lists']['']['facets'] }) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].facets = value

    // Check if initial facets needs to be updated,
    // i.e.if initialFacets is a subset of facets(and not the other way around)
    const initialFacets = Object.values(state.lists[id].initialFacets ?? {})
    if (
      initialFacets.length < Object.keys(value ?? {}).length ||
      initialFacets.some(initFacets => !deepEquals(Object.keys(initFacets), Object.keys(value ?? {})))
    ) {
      state.lists[id].initialFacets = value
    }
  },
  facetFilters (state, { id, value }: { id: string, value: FacetFilter[] }) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].facetFilters = value
  },
  initialFacetFilters (state, { id, value }: { id: string, value: FacetFilter[] }) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].initialFacetFilters = value
  },
  searchQuery (state, { id, value }: { id: string, value: string }) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].searchQuery = value
  },
  resetSearchQuery (state, id: string) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].searchQuery = ''
  },
  results (state, { id, value }: { id: string, value: SearchResponse<AlgoliaProduct> }) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    const tempList: any = {}
    if (value) {
      tempList.products = value.hits
      tempList.totalProducts = value.nbHits
      tempList.searchQuery = value.query
      const tempFacets = structuredClone(state.lists[id].facets)
      const resetFacets: { [key: string]: any } = {}
      for (const sizechart in tempFacets) {
        if (sizechart.startsWith('sizechart')) {
          if (state.lists[id]?.initialFacets?.[sizechart]) {
            resetFacets[sizechart] = state.lists[id].initialFacets?.[sizechart]
          }
        }
      }
      tempList.facets = { ...value.facets, color: state.lists[id].initialFacets?.color || {}, ...resetFacets }

      // Check if initial facets needs to be updated,
      // i.e.if initialFacets is a subset of facets(and not the other way around)
      const initialFacets = Object.entries(state.lists[id].initialFacets ?? {})
      if (
        initialFacets.length < Object.keys(value.facets ?? {}).length ||
        initialFacets.some(([key, initFacetsOnKey]) => Object.keys(initFacetsOnKey).length < Object.keys((value.facets ?? {})[key] ?? {}).length)
      ) {
        tempList.initialFacets = value.facets
      }
      tempList.queryID = value.queryID
      state.lists = { ...state.lists, [id]: { ...state.lists[id], ...tempList } }
    }
  },
  createList (state, id: string) {
    if (!(id in state.lists)) {
      state.lists[id] = {
        currentConsumer: false,
        searchQuery: '',
        offset: 0,
        limit: 20,
        pageSize: 20,
        facetFilters: [],
        initialFacetFilters: [],
        facets: undefined,
        initialFacets: undefined,
        algoliaMerchandisingRule: undefined,
        productsFrom: undefined,
        products: undefined,
        totalProducts: Infinity,
        queryID: undefined,
        listenToQueryParams: id === ALGOLIA_KEY_MAIN,
        oldQuery: undefined,
        consumerWasDestroyed: false,
      }
    }
  },
  emptyList (state, id: string) {
    if (id === ALGOLIA_KEY_SEARCH_RESULTS) {
      return
    }
    state.lists[id].consumerWasDestroyed = true
    if (ALGOLIA_KEYS.includes(id)) {
      return
    }
    if (id in state.lists) {
      delete state.lists[id]
    }
  },
  /**
   * @deprecated
   */
  __copyListToMain (state, from: string) {
    if (!from || !(from in state.lists)) {
      console.warn(`The id ${from} is not in the Algolia product lists`)
      return
    }

    const {
      // Don't copy these settings
      currentConsumer,
      limit,
      pageSize,
      algoliaMerchandisingRule,
      listenToQueryParams,
      ...rest
    } = state.lists[ALGOLIA_KEY_MAIN]

    state.lists[ALGOLIA_KEY_MAIN] = {
      ...rest,
      ...structuredClone(state.lists[from]),
      currentConsumer,
      limit,
      pageSize,
      algoliaMerchandisingRule,
      listenToQueryParams,
    }
  },
  listSettings (
    state,
    _settings: {
    id: string,
    productsFrom?: AlgoliaState['lists']['']['productsFrom'],
    algoliaMerchandisingRule?: AlgoliaState['lists']['']['algoliaMerchandisingRule'],
    limit?: AlgoliaState['lists']['']['limit'],
    pageSize?: AlgoliaState['lists']['']['pageSize'],
    offset?: AlgoliaState['lists']['']['offset'],
    currentConsumer?: AlgoliaState['lists']['']['currentConsumer'],
  }) {
    const { id, ...settings } = _settings
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }

    if ('productsFrom' in settings) {
      state.lists[id].productsFrom = settings.productsFrom
    }
    if ('algoliaMerchandisingRule' in settings) {
      state.lists[id].algoliaMerchandisingRule = settings.algoliaMerchandisingRule
    }

    const { currentConsumer, limit, pageSize, offset } = settings
    if (typeof currentConsumer !== 'undefined') {
      state.lists[id].currentConsumer = currentConsumer
    }
    if (typeof limit !== 'undefined') {
      if (typeof limit === 'string') {
        const asInt = parseInt(limit)
        state.lists[id].limit = isNaN(asInt) ? 20 : asInt
      } else {
        state.lists[id].limit = limit
      }
    }
    if (typeof pageSize !== 'undefined') {
      if (typeof pageSize === 'string') {
        const asInt = parseInt(pageSize)
        state.lists[id].pageSize = isNaN(asInt) ? 20 : asInt
      } else {
        state.lists[id].pageSize = pageSize
      }
    }
    if (typeof offset !== 'undefined') {
      if (typeof offset === 'string') {
        const asInt = parseInt(offset)
        state.lists[id].offset = isNaN(asInt) ? 0 : asInt
      } else {
        state.lists[id].offset = offset
      }
    }
  },
  oldQuery (state, { id, value }: {
    id: string,
    value: AlgoliaState['lists']['']['oldQuery']
  }) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].oldQuery = md5(value ?? '')
  },
  consumerWasDestroyed (state, { id, value }: { id: string, value: AlgoliaState['lists']['']['consumerWasDestroyed']}) {
    if (!id || !(id in state.lists)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return
    }
    state.lists[id].consumerWasDestroyed = value
  },
  addRuleCondition (state, { objectID, value }: { objectID: string, value: AlgoliaState['ruleConditions'][''] }) {
    if (typeof state.ruleConditions === 'undefined') {
      state.ruleConditions = {}
    }
    state.ruleConditions[objectID] = value
  },
  canonicalFacetFilters: (state, canonicalFacetFilters: string) => {
    state.canonicalFacetFilters = canonicalFacetFilters
  },
}

export const actions: ActionTree<RootState['algolia'], RootState> = {
  async setSearchQuery ({ getters, commit, dispatch }, { id, value }: { id: string, value: string }) {
    if (!getters.isInLists(id)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return Promise.resolve()
    }
    if (getters.searchQuery(id) !== value) {
      commit('searchQuery', { id, value })
      if (value.length >= 2) {
        await dispatch('executeQuery', id)
      }
    }
  },
  async loadMore ({ getters, commit, dispatch }, id: string) {
    if (!getters.isInLists(id)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return Promise.resolve()
    }
    commit('listSettings', {
      id,
      limit: Math.min(
        getters.totalProducts(id),
        Math.max(getters.limit(id) + getters.pageSize(id), getters.products(id)?.length ?? 0) // HERE!!!! getters.results.length????
      )
    })
    await dispatch('executeQuery', id)
  },
  async resetFilters ({ rootState, getters, commit, dispatch }, id: string) {
    if (!getters.isInLists(id)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return Promise.resolve()
    }
    const newQuery = structuredClone(rootState.route.query) ?? {}
    // Remove all whitelisted param names from query params
    for (const [queryParamKey, valueRaw] of Object.entries(newQuery)) {
      const formatted = formatFacetFilterFromQuery({
        facetName: queryParamKey,
        value: valueRaw,
        sizechartStandard: getters.sizechartStandard,
        facets: getters.facets(id)
      })
      if (!formatted) {
        // Not a facet filter
        continue
      }

      delete newQuery[queryParamKey]
    }

    let shouldQuery = false
    if (!deepEquals(getters.facetFilters(id), getters.initialFacetFilters(id) ?? [])) {
      commit('facetFilters', { id, value: getters.initialFacetFilters(id) ?? [] })
      shouldQuery = true
    }
    if (!deepEquals(rootState.route.query, newQuery)) {
      this.$router.push({ query: newQuery })
    }

    if (shouldQuery) {
      await dispatch('executeQuery', id)
    }
  },
  async toggleFacet (
    { getters, dispatch, commit, rootState },
    { facetName, value, id }: { facetName: string, value: string, id: string }
  ) {
    if (!getters.isInLists(id)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return Promise.resolve()
    }
    const facetFilters: FacetFilter[] = getters.facetFilters(id) ?? []
    if (!facetFilters) {
      return
    }
    let newFacetFilters = structuredClone(facetFilters)

    const facetFilterActive = facetFilters.findIndex(ff => ff.facetName === facetName && ff.value === value) !== -1
    if (facetFilterActive) {
      // Remove
      newFacetFilters = newFacetFilters.filter(q => q.value !== value)
    } else {
      // Add
      newFacetFilters = [
        ...newFacetFilters,
        { facetName, value }
      ]
    }

    if (!deepEquals(facetFilters, newFacetFilters)) {
      const newQuery = facetFilterToQuery({
        facetName,
        value,
        query: rootState.route.query,
        wasAdded: !facetFilterActive
      })

      if (typeof newQuery !== 'undefined') {
        // If we have new query params, handleQueryParams will add facet filters and execute query
        this.$router.push({ query: newQuery })
      } else {
        commit('facetFilters', { id, value: newFacetFilters })
        await dispatch('executeQuery', id)
      }
    }
  },
  async handleQueryParams (
    { rootState, getters, commit, dispatch },
    { id, skipRequest = false } : { id: string, skipRequest: boolean }
  ) {
    if (!getters.isInLists(id)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return Promise.resolve()
    }

    if (!getters.listenToQueryParams(id) || !Object.keys(getters.facets(id) ?? {}).length) {
      return Promise.resolve()
    }

    const query = structuredClone(rootState.route.query ?? {})
    let searchQuery = ''
    if ('q' in query && typeof query.q !== 'undefined') {
      commit('listSettings', { id, productsFrom: undefined, algoliaMerchandisingRule: undefined })
      searchQuery = escape([query.q].flat().join(''))
      if (typeof searchQuery === 'string' && searchQuery.length >= 2) {
        commit('searchQuery', { id, value: searchQuery })
      } else {
        commit('resetSearchQuery', id)
      }
      delete query.q
    }
    const hasSetSearchQuery = typeof searchQuery === 'string' && searchQuery.length >= 2

    const newFacetFilters = facetFiltersfromQuery(query, getters.sizechartStandard, getters.facetFilters(id), getters.facets(id))

    const hasNewFacetsFilters = !deepEquals(getters.facetFilters(id), newFacetFilters)
    if (hasNewFacetsFilters) {
      commit('facetFilters', { id, value: newFacetFilters })
    }

    if (!skipRequest || (!skipRequest && (hasNewFacetsFilters || hasSetSearchQuery))) {
      await dispatch('executeQuery', id)
    }
  },
  isQueryNeeded ({ getters }, id: string) {
    // Check if we actually need to query Algolia again
    const oldQuery: AlgoliaState['lists']['']['oldQuery'] = getters.oldQuery(id)
    const newState = md5(JSON.stringify({
      searchQuery: getters.searchQuery(id),
      offset: getters.offset(id),
      limit: getters.limit(id),
      facetFilters: getters.facetFilters(id),
      algoliaMerchandisingRule: getters.algoliaMerchandisingRule(id),
      productsFrom: getters.productsFrom(id),
    }))

    return [oldQuery !== newState, newState]
  },
  async executeQueryForFacets ({ commit, getters, dispatch }, id: string) {
    const tempFacetFilters = structuredClone(getters.facetFilters(id))
    commit('facetFilters', { id, value: getters.initialFacetFilters(id) })
    const queryRes = await dispatch('_executeQuery', id)
    commit('facetFilters', { id, value: tempFacetFilters })
    if (queryRes) {
      commit('facets', { id, value: queryRes.facets })
    }
  },
  async executeQuery ({ commit, dispatch }, id: string) {
    const [needsQuery, newQuery] = await dispatch('isQueryNeeded', id)
    if (!needsQuery) {
      return
    }

    const results = await dispatch('_executeQuery', id)
    if (results) {
      commit('results', { id, value: results })
      // Update oldQuery to newState
      commit('oldQuery', { id, value: newQuery })
      commit('consumerWasDestroyed', { id, value: false })
    }
  },
  async _executeQuery ({ getters, commit, rootGetters }, id: string) {
    if (!getters.isInLists(id)) {
      console.warn(`The id ${id} is not in the Algolia product lists`)
      return undefined
    }

    const searchClient = getAlgoliaSearchClient(
      rootGetters['frontend/market'].name,
      this.app.$config.algolia.appId,
      this.app.$config.algolia.searchApiKey
    )

    let searchQuery = getters.searchQuery(id)
    if (typeof searchQuery !== 'string' || searchQuery.length < 2) {
      searchQuery = ''
    }
    type Mutable<T> = { -readonly [P in keyof T]: T[P] };
    const query: Mutable<Parameters<SearchIndex['search']>[1]> = {
      facets: ['*'],
      offset: getters.offset(id),
      length: getters.limit(id),
      clickAnalytics: true,
      sortFacetValuesBy: 'alpha'
    }

    if (getters.facetFilters(id)?.length) {
      const facetFilters = produceLabelFacets(getters.facetFilters(id), getters.sizechartStandard)
      const colorFacerts: string[] = []
      const sizeFacets: string[] = []
      const facetFiltersList: string[] = []
      facetFilters.forEach((facetFilter) => {
        if (facetFilter.facetName === 'color') {
          colorFacerts.push(`${facetFilter.facetName}:${facetFilter.value}`)
        } else if (facetFilter.facetName.includes('size')) {
          sizeFacets.push(`${facetFilter.facetName}:${facetFilter.value}`)
        } else {
          facetFiltersList.push(`${facetFilter.facetName}:${facetFilter.value}`)
        }
      })
      if (facetFiltersList.length > 0) {
        query.facetFilters = facetFiltersList
      }
      if (Array.isArray(query.facetFilters) && colorFacerts.length > 0) {
        query.facetFilters.push(colorFacerts)
      }
      if (Array.isArray(query.facetFilters) && sizeFacets.length > 0) {
        query.facetFilters.push(sizeFacets)
      }
    }

    const results = await searchClient.search<CentraProduct>(searchQuery, query)
    if (!Object.keys(getters.initialFacets(id)).length || id === ALGOLIA_KEY_SEARCH_RESULTS) {
      // Update initial facets if empty or always on text search results.
      // This is to get grayed out facets in the filter options
      commit('initialFacets', { id, value: results.facets ?? {} })
    }
    return results
  },
  calculateCanonicalFacetFilters ({ commit, rootState, getters }) {
    const facetFilters = facetFiltersfromQuery(
      rootState.route.query,
      getters.sizechartStandard,
      getters.facetFilters(ALGOLIA_KEY_MAIN),
      getters.facets(ALGOLIA_KEY_MAIN)
    )
    if (!facetFilters?.length) {
      commit('canonicalFacetFilters', '')
      return
    }
    const formattedFacetFilters = facetFiltersToQuery(facetFilters)
    if (typeof formattedFacetFilters === 'undefined') {
      commit('canonicalFacetFilters', '')
      return
    }

    const newQuery = new URLSearchParams()
    for (const [key, values] of Object.entries(formattedFacetFilters)) {
      if (!key.includes('size') && !key.includes('color')) {
        continue
      }
      for (const value of values) {
        if (value !== null) {
          newQuery.append(key, value)
        }
      }
    }

    commit('canonicalFacetFilters', '?' + newQuery.toString())
  },
}

// ============================== Utility Function ==============================
function getAlgoliaClient (appId: string, searchApiKey: string) {
  if (process.client) {
    if (!window.algoliaClient) {
      window.algoliaClient = algoliaSearch(appId, searchApiKey, {
        authMode: AuthMode.WithinQueryParameters
      })
    }
    return window.algoliaClient
  } else {
    return algoliaSearch(appId, searchApiKey)
  }
}

function getAlgoliaSearchClient (marketName: string, appId: string, searchApiKey: string) {
  const algoliaClient = getAlgoliaClient(appId, searchApiKey)
  return Object.assign({}, algoliaClient.initIndex(marketName))
}

function produceLabelFacets (facetFilters: AlgoliaState['lists']['']['facetFilters'], sizechartStandard: string) {
  const cupTypeFacetName = `sizechart.sizechart_${sizechartStandard}.cup_type`
  const typeFacetName = `sizechart.sizechart_${sizechartStandard}.type`
  const typeAndCupTypeNames = [typeFacetName, cupTypeFacetName]
  const facetNamesEnabled = facetFilters.map(f => f.facetName)
  const typeAndCupTypeEnabled = typeAndCupTypeNames.every(f => facetNamesEnabled.includes(f))

  if (typeAndCupTypeEnabled) {
    const allTypeFacets = facetFilters.filter(f => f.facetName === typeFacetName).map(f => f.value)
    const allCupTypeFacets = facetFilters.filter(f => f.facetName === cupTypeFacetName).map(f => f.value)
    const labelFacets = allTypeFacets.flatMap(typeFacetValue =>
      allCupTypeFacets.map(cupTypeFacetValue =>
        ({
          facetName: `sizechart.sizechart_${sizechartStandard}.label`,
          value: `${typeFacetValue}x${cupTypeFacetValue}`,
        })
      )
    )

    return [
      ...(facetFilters.filter(f => !typeAndCupTypeNames.includes(f.facetName))),
      ...labelFacets,
    ]
  }

  return facetFilters
}

export function facetFiltersfromQuery (
  query: Route['query'],
  sizechartStandard: string | undefined,
  facetFilters: FacetFilter[],
  facets: AlgoliaState['lists']['']['facets']
) {
  const newFacetFilters = structuredClone(facetFilters)
    .filter(({ facetName }) => !getWhitelistedFilterFacetNames().includes(facetName))
  for (const [paramName, valueInQuery] of Object.entries(query)) {
    const formatted = formatFacetFilterFromQuery({
      facetName: paramName,
      value: valueInQuery,
      sizechartStandard,
      facets
    })
    if (!formatted) {
      // Not a facet filter
      continue
    }

    for (const { facetName, value } of formatted) {
      if (typeof value === 'undefined' || newFacetFilters.some(ff => ff.facetName === facetName && ff.value === value)) {
        continue
      }
      newFacetFilters.push({
        facetName,
        value,
      })
    }
  }
  return newFacetFilters
}

export function facetFiltersToQuery (facetFilters: FacetFilter[]) {
  let ƒacetQuery: Record<string, (string | null)[]> = {}
  for (const ff of facetFilters) {
    ƒacetQuery = facetFilterToQuery({
      ...ff,
      wasAdded: true,
      query: ƒacetQuery
    })!
  }
  return ƒacetQuery
}

export function facetFilterToQuery ({ facetName, value: valueRaw, query, wasAdded }: { facetName: string, value: string, query?: Route['query'], wasAdded: boolean }) {
  const newQuery = (structuredClone(query) ?? {}) as Record<string, Array<null | string>>

  const formatted = formatFacetFilterToQuery(facetName, valueRaw)
  if (!formatted) {
    // Cannot put this facetFilter in query params
    return undefined
  }

  const { facetName: key, value } = formatted

  if (key in newQuery) {
    newQuery[key] = [newQuery[key]].flat() as Array<string | null>

    if (newQuery[key].includes(value) && !wasAdded) {
      // remove
      newQuery[key] = newQuery[key].filter(q => q !== value)
    } else if (!newQuery[key].includes(value) && wasAdded) {
      // add
      newQuery[key].push(value)
      // sort the values on each query key
      newQuery[key].sort()
    }
  } else if (!(key in newQuery) && wasAdded) {
    newQuery[key] = [value]
  }

  if ((query && !deepEquals(query, newQuery)) || !query) {
    // Sort the keys in query
    return Object.fromEntries(Object.entries(newQuery).sort(([key1], [key2]) => {
      if (key1 < key2) {
        return -1
      } else if (key1 > key2) {
        return 1
      } else {
        return 0
      }
    }))
  } else {
    return undefined
  }
}
