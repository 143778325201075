import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2d0a7e1b&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=2d0a7e1b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0a7e1b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomActionController: require('/usr/src/app/components/custom-action/CustomActionController.vue').default,RichTextSchema: require('/usr/src/app/components/rich-text-schema/RichTextSchema.vue').default,YotpoController: require('/usr/src/app/components/yotpo/YotpoController.vue').default,TopBanner: require('/usr/src/app/components/top-banner/TopBanner.vue').default,Header: require('/usr/src/app/components/header/Header.vue').default,FilterAccordionBlock: require('/usr/src/app/components/accordion-block/FilterAccordionBlock.vue').default,Footer: require('/usr/src/app/components/footer/Footer.vue').default})
