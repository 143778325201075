import defaultFrontendStore from '@made-people/centra-storyblok-nuxt-shared/lib/store/frontend'

export class ContextError extends Error {}
export class CountryMissing extends ContextError {}
export class LanguageMissing extends ContextError {}

// Putting countries in order here will put them first in the selector in the header
export const countrySortOrder = [
]

// The centra market IDs in this list will not have a /sitelangslug and
// only a default language
// const marketsWithSlug = [
//   12 // "missmaryofsweden.com"
// ]

/**
 * 'route' should be a vue-router route object
 */
export const routeToLookupParts = ({ route, defaultLocale, defaultMarketSlug, contexts }) => {
  defaultLocale = defaultLocale || 'en'
  // Set up defaults
  const parts = {
    country: defaultMarketSlug,
    currentPath: defaultMarketSlug, // This "works" until the default slug contains a language as well
    defaultLocale,
    storyblokEntry: undefined,
    language: undefined,
  }

  const routeParts = route.fullPath.split('/')
  parts.currentPath = routeParts[1]

  if (route.query._storyblok) {
    // Inside storyblok edit mode
    const slugParts = routeParts[1].split('-')
    if (slugParts.length > 1) {
      parts.language = slugParts[0]
      parts.currentPath = slugParts[1]
    }

    parts.currentPath =
      contexts.countries.find(country => (country.language === parts.currentPath) || (country.country === `${parts.currentPath}`.toUpperCase()))?.country || 'us'
  } else {
    const splitSlug = routeParts[1].replace(/\?.*/, '').split('-')
    parts.country = splitSlug[0].split('?')[0]
  }

  parts.storyblokEntry = routeParts.slice(2).join('/')

  return parts
}

export const lookupPartsToSiteSlug = (parts) => {
  const slugParts = [parts.country]
  if (parts.language) {
    slugParts.push(parts.language)
  }
  return slugParts.join('-')
}

// We're currently browsing inside storyblok and need to manage
// that in some way because SB urls do not match the live site urls
export const isBrowsingViaStoryblok = (route) => {
  return !!route.query._storyblok
}

export const getLookupRouteFromRoute = (route, getters) => {
  let slug = route.fullPath.replace(/^\//, '')
  if (!isBrowsingViaStoryblok(route)) {
    slug = ['site'].concat(route.fullPath.split('/').slice(2)).join('/') // Assumes [$market-$lang] exists in the beginning of the url
    if (!/\d+/.test(slug)) {
      slug = getters.getLinkForSlug(slug).id
    }
  } else if (/site\/site/.test(slug)) {
    return 'site'
  }
  return slug
}

export default {
  state () {
    return {
      ...defaultFrontendStore.state(),
      pricelistAll: [],
      pricelist: null,
      market: null,
      currentDomain: null,
      marketsList: [],
      geoIpCountryCode: null,
      geoIpCountry: null,
      mpHostname: null,

      whitelistedQueryParams: {}
    }
  },

  mutations: {
    currentCountryCode: defaultFrontendStore.mutations.currentCountryCode,
    currentLanguageCode: defaultFrontendStore.mutations.currentLanguageCode,
    countries: defaultFrontendStore.mutations.countries,
    languages: defaultFrontendStore.mutations.languages,
    currentCampaignUri: defaultFrontendStore.mutations.currentCampaignUri,
    currentContext: defaultFrontendStore.mutations.currentContext,
    ssrHeaders: defaultFrontendStore.mutations.ssrHeaders,
    pricelistAll (state, pricelistAll) {
      state.pricelistAll = pricelistAll
    },
    pricelist (state, pricelist) {
      state.pricelist = pricelist
    },
    market (state, market) {
      state.market = market
    },
    currentDomain (state, currentDomain) {
      state.currentDomain = currentDomain
    },
    marketsList (state, marketsList) {
      state.marketsList = marketsList
    },
    geoIpCountryCode (state, countryCode) {
      state.geoIpCountryCode = countryCode
    },
    geoIpCountry (state, country) {
      state.geoIpCountry = country
    },
    mpHostname (state, mpHostname) {
      state.mpHostname = mpHostname
    },

    whitelistedQueryParams (state, whitelistedQueryParams) {
      state.whitelistedQueryParams = whitelistedQueryParams
    }
  },

  actions: {
    setCurrentSite: defaultFrontendStore.actions.setCurrentSite,
    setCurrentCountryCode: defaultFrontendStore.actions.setCurrentCountryCode,
    setCurrentLanguageCode: defaultFrontendStore.actions.setCurrentLanguageCode,
    setCurrentLanguage: defaultFrontendStore.actions.setCurrentLanguage,
    setCurrentCampaignUri: defaultFrontendStore.actions.setCurrentCampaignUri,
    removeCurrentCampaignUri: defaultFrontendStore.actions.removeCurrentCampaignUri,
    setCurrentContext: defaultFrontendStore.actions.setCurrentContext,

    /**
     * Store params from the query string, so we can append them to internal URLs
     * and get better attribution data.
     */
    storeURLTrackingParameters: ({ commit }, { route }) => {
      if (typeof route?.query !== 'object') {
        return
      }

      const whitelistedQueryParamKeys = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term',
        'utm_id',
        'utm_campaign_id',
        'utm_gads',

        'fbclid',
        'gclid',
        'ttclid',
        'msclkid',
        '_kx'
      ]

      const whitelistedQueryParams = whitelistedQueryParamKeys.reduce((acc, key) => {
        if (route.query[key]) {
          acc[key] = route.query[key]
        }
        return acc
      }, {})

      commit('whitelistedQueryParams', whitelistedQueryParams)
    },
  },

  getters: {
    market: state => state.market,
    currentDomain: state => state.currentDomain,
    marketsList: state => state.marketsList,
    countries: state => state.countries,
    pricelist: state => state.pricelist,
    pricelistAll: state => state.pricelistAll,
    currentCountry: defaultFrontendStore.getters.currentCountry,
    currentCountryCode: defaultFrontendStore.getters.currentCountryCode,
    currentLanguageCode: defaultFrontendStore.getters.currentLanguageCode,
    currentMarketId: (state, getters) => getters.market?.market,
    currentPricelistId: (state, getters) => getters.pricelist?.pricelist,
    currentContext: defaultFrontendStore.getters.currentContext,
    currentCampaign: defaultFrontendStore.getters.currentCampaign,
    currentLanguage: defaultFrontendStore.getters.currentLanguage,

    // No secondary language in miss mary
    currentSiteLangSlug: state => state.currentCountryCode.toLowerCase(),

    getMarketByCountryCode: defaultFrontendStore.getters.getMarketByCountryCode,
    getCurrencyCodeByCountryCode: defaultFrontendStore.getters.getCurrencyCodeByCountryCode,
    countriesSorted: defaultFrontendStore.getters.countriesSorted,
    marketCountriesSorted: defaultFrontendStore.getters.marketCountriesSorted,
    availableLanguages: defaultFrontendStore.getters.availableLanguages,
    ssrHeaders: defaultFrontendStore.getters.ssrHeaders,

    geoIpCountryCode: state => state.geoIpCountryCode,
    geoIpCountry: state => state.geoIpCountry,
    mpHostname: state => state.mpHostname,

    getPathWithWhitelistedQueryParams: state => (to) => {
      const whitelistedQueryParams = state.whitelistedQueryParams

      const [path, query] = to.split('?')
      const newQuery = new URLSearchParams(query)
      for (const [key, value] of Object.entries(whitelistedQueryParams)) {
        newQuery.set(key, value)
      }
      return `${path}?${newQuery.toString()}`
    }
  }
}
