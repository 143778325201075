// @ts-ignore
import UI from '@made-people/centra-storyblok-nuxt-shared/lib/store/ui'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { CentraProduct } from '@made-people/centra-models'
import { RootState } from './types'

export function state () {
  const state: RootState['ui'] = {
    mobileSidebarOpen: false,
    desktopSubmenuOpen: false,
    miniCartOpen: false,
    searchOpen: false,
    searchAutocompleteResult: null,
    mobileProductFilterOpen: false,
    enableDiscountFilter: false,
    currentQueryName: null,
    selectedProductID: '',
    isProductDiscontinued: '',
    productItems: null,
    filterAccordionBlockData: null,
    filterAccordionBlockOpen: false,
    sizeSelectorMobileOpen: false,
    sizeSelectorDesktopOpen: false,
    productRestockSidebarOpen: false,
    addedProduct: '',
    addedPrimarySize: '',
    addedSecondarySize: '',
    washingInstructions: false,
    sizeGuide: false,
    productType: null,
    questionsSidebarOpen: false,
    selectedProductSize: false,
    productReviewsSidebarOpen: false,
    productQuestionsSidebarOpen: false,
    selectedProductSizeMatching: {},
    cartPopupOpenMobile: false,
    cartPopupOpenDesktop: false,
    countrySelectorPopup: false,
    addToCartLoadingMatchingProducts: null,
    addToCartLoadingMatchingSwimwearProducts: null,
    lastClickedQueryID: null,
    product: null,
    itemId: null,
    overlayIndex: 0,
    freeShippingEnabled: false,
    algoliaProductFilter: {
      open: false,
      tab: undefined
    },
    isMobile: false,
  }

  return { ...UI.state(), ...state }
}

export const getters: GetterTree<RootState['ui'], RootState> = {
  ...UI.getters,
  cartPopupOpenMobile: state => state.cartPopupOpenMobile,
  cartPopupOpenDesktop: state => state.cartPopupOpenDesktop,
  isMobile: state => state.isMobile,
}

export const mutations: MutationTree<RootState['ui']> = {
  ...UI.mutations,
  product (state, product?: CentraProduct) {
    state.product = product ?? null
  },
  /**
   * Mobile Sidebar
   */
  showMobileSidebar (state) {
    // Close everything else
    state.searchOpen = false
    state.desktopSubmenuOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.filterAccordionBlockOpen = false
    // Open sidebar
    state.mobileSidebarOpen = true
    state.overlayIndex = 11
  },
  hideMobileSidebar (state) {
    state.mobileSidebarOpen = false
    state.overlayIndex = 0
  },

  /**
   * Desktop Submenu
   */
  showDesktopSubmenu (state) {
    // Close everything else
    state.searchOpen = false
    state.mobileSidebarOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.filterAccordionBlockOpen = false
    // Open submenu
    state.desktopSubmenuOpen = true
    state.overlayIndex = 9
  },
  hideDesktopSubmenu (state) {
    state.desktopSubmenuOpen = false
    state.overlayIndex = 0
  },

  showCountrySelectorPopup (state) {
    state.countrySelectorPopup = true
    state.overlayIndex = 11
  },
  hideCountrySelectorPopup (state) {
    state.countrySelectorPopup = false
    state.overlayIndex = 0
  },

  /**
   * Minicart
   */
  showMiniCart (state) {
    // Close everything else
    state.searchOpen = false
    state.mobileSidebarOpen = false
    state.desktopSubmenuOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.sizeSelectorDesktopOpen = false
    state.filterAccordionBlockOpen = false
    state.cartPopupOpenMobile = false
    state.cartPopupOpenDesktop = false
    // Open minicart
    state.miniCartOpen = true
    state.overlayIndex = 11
  },
  hideMiniCart (state) {
    state.miniCartOpen = false
    state.overlayIndex = 0
  },

  /**
   * Add to cart
   */
  showCartPopupMobile (state, { addedProduct, addedPrimarySize, addedSecondarySize }) {
    state.addedProduct = addedProduct
    state.addedPrimarySize = addedPrimarySize
    state.addedSecondarySize = addedSecondarySize

    // Close everything else
    state.searchOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.desktopSubmenuOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.sizeSelectorDesktopOpen = false
    state.filterAccordionBlockOpen = false
    state.cartPopupOpenDesktop = false
    // Open minicart
    state.cartPopupOpenMobile = true
    state.overlayIndex = 11
  },
  hideCartPopupMobile (state) {
    state.cartPopupOpenMobile = false
    state.addedProduct = ''
    state.addedPrimarySize = ''
    state.addedSecondarySize = ''
    state.overlayIndex = 0
  },

  showCartPopupDesktop (state, { addedProduct }) {
    state.addedProduct = addedProduct

    // Close everything else
    state.searchOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.desktopSubmenuOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.sizeSelectorDesktopOpen = false
    state.filterAccordionBlockOpen = false
    state.cartPopupOpenMobile = false
    // Open minicart
    state.cartPopupOpenDesktop = true
    state.overlayIndex = 11
  },
  hideCartPopupDesktop (state) {
    state.cartPopupOpenDesktop = false
    state.addedProduct = ''
    state.overlayIndex = 0
  },

  /**
   * Search
   */
  showSearch (state) {
    // Close everything else
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.desktopSubmenuOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.filterAccordionBlockOpen = false
    // Open search
    state.searchOpen = true
    state.overlayIndex = 9
  },
  hideSearch (state) {
    state.searchOpen = false
    state.overlayIndex = 0
  },

  /**
   * Mobile Product Filter Sidebar
   */
  showMobileProductFilter (state, { queryName, enableDiscountFilter }) {
    // Every time we show the filter menu we replace what's inside it, THAT'S THE ONLY WAY OK!
    state.currentQueryName = queryName
    state.enableDiscountFilter = enableDiscountFilter

    // Close everything else
    state.searchOpen = false
    state.desktopSubmenuOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    state.filterAccordionBlockOpen = false
    // Open filter
    state.mobileProductFilterOpen = true
    // state.overlayIndex = 11
  },
  hideMobileProductFilter (state) {
    state.mobileProductFilterOpen = false
    state.overlayIndex = 0
  },

  /**
   * Filter Selector
   */
  showfilterAccordionBlock (state, { filterAccordionBlockData }) {
    state.filterAccordionBlockData = filterAccordionBlockData

    // Close everything else
    state.searchOpen = false
    state.desktopSubmenuOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.sizeSelectorMobileOpen = false
    // Open Size Selector
    state.filterAccordionBlockOpen = true
    state.overlayIndex = 12
  },
  hidefilterAccordionBlock (state) {
    state.filterAccordionBlockData = null
    state.filterAccordionBlockOpen = false
    state.overlayIndex = 0
  },

  /**
   * Size Selector Popup
   */
  showSizeSelectorMobile (state, { productID, discontinued, productItems }) {
    state.selectedProductID = productID
    state.isProductDiscontinued = discontinued
    state.productItems = productItems

    // Close everything else
    state.searchOpen = false
    state.desktopSubmenuOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.filterAccordionBlockOpen = false
    // Open Size Selector
    state.sizeSelectorMobileOpen = true
    state.overlayIndex = 11
  },
  hideSizeSelectorMobile (state) {
    state.sizeSelectorMobileOpen = false
    state.selectedProductID = ''
    state.isProductDiscontinued = ''
    state.productItems = null
    state.overlayIndex = 0
  },

  showSizeSelectorDesktop (state, { productID, discontinued, productItems }) {
    state.selectedProductID = productID
    state.isProductDiscontinued = discontinued
    state.productItems = productItems

    // Close everything else
    state.searchOpen = false
    state.desktopSubmenuOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.mobileProductFilterOpen = false
    state.productRestockSidebarOpen = false
    state.filterAccordionBlockOpen = false
    // Open Size Selector
    state.sizeSelectorDesktopOpen = true
    state.overlayIndex = 11
  },
  hideSizeSelectorDesktop (state) {
    state.sizeSelectorDesktopOpen = false
    state.selectedProductID = ''
    state.isProductDiscontinued = ''
    state.productItems = null
    state.overlayIndex = 0
  },

  /**
   * Product Restock Sidebar
   */
  showProductRestockSidebar (state, itemId) {
    // We can use this here as well
    // state.currentQueryName = queryName
    state.itemId = itemId

    // Close everything else
    state.searchOpen = false
    state.desktopSubmenuOpen = false
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.mobileSidebarOpen = false
    state.mobileProductFilterOpen = false
    state.sizeSelectorMobileOpen = false
    state.filterAccordionBlockOpen = false
    // Open product restock
    state.productRestockSidebarOpen = true
    state.overlayIndex = 11
  },
  hideProductRestockSidebar (state) {
    state.productRestockSidebarOpen = false
    state.overlayIndex = 0
    state.itemId = null
  },

  /**
   * Washing Instructions
   */
  showWashingInstructions (state) {
    state.washingInstructions = true
    state.overlayIndex = 11
  },

  /**
   * Size Guide
   */
  showSizeGuide (state, productType) {
    state.productType = productType
    state.sizeSelectorDesktopOpen = false
    state.sizeGuide = true
    state.overlayIndex = 11
  },

  /**
   * Selected product size
   */
  selectedProductSize (state, selectedProductSize) {
    state.selectedProductSize = selectedProductSize
  },

  /**
   * Product reviews
   */
  showProductReviewsSidebar (state, product) {
    state.sizeSelectorMobileOpen = false
    state.sizeSelectorDesktopOpen = false
    state.filterAccordionBlockOpen = false
    state.productQuestionsSidebarOpen = false

    state.product = product
    state.overlayIndex = 11
    state.productReviewsSidebarOpen = true
  },
  hideProductReviewsSidebar (state) {
    state.productReviewsSidebarOpen = false
    state.overlayIndex = 0
  },

  /**
   * Product questions
   */
  showProductQuestionsSidebar (state, product) {
    state.sizeSelectorMobileOpen = false
    state.sizeSelectorDesktopOpen = false
    state.filterAccordionBlockOpen = false
    state.productReviewsSidebarOpen = false

    state.product = product
    state.overlayIndex = 11
    state.productQuestionsSidebarOpen = true
  },
  hideProductQuestionsSidebar (state) {
    state.overlayIndex = 0
    state.productQuestionsSidebarOpen = false
  },

  /**
   * Selected product size
   */
  selectedProductSizeMatching (state, { selectedItem, selectedSize }) {
    state.selectedProductSizeMatching[selectedItem] = selectedSize
  },

  /**
   * Matching Product Spinner
   */
  addToCartLoadingMatchingProducts (state, productId) {
    state.addToCartLoadingMatchingProducts = productId
  },

  addToCartLoadingMatchingSwimwearProducts (state, productId) {
    state.addToCartLoadingMatchingSwimwearProducts = productId
  },

  lastClickedQueryID (state, queryID) {
    state.lastClickedQueryID = queryID
  },

  freeShippingEnabled (state, freeShippingEnabledStatus) {
    state.freeShippingEnabled = freeShippingEnabledStatus
  },

  /**
   * Close All
   */
  closeAll (state) {
    state.miniCartOpen = false
    state.countrySelectorPopup = false
    state.searchOpen = false
    state.mobileSidebarOpen = false
    state.desktopSubmenuOpen = false
    state.mobileProductFilterOpen = false
    state.sizeSelectorMobileOpen = false
    state.sizeSelectorDesktopOpen = false
    state.filterAccordionBlockOpen = false
    state.productRestockSidebarOpen = false
    state.washingInstructions = false
    state.sizeGuide = false
    state.productReviewsSidebarOpen = false
    state.productQuestionsSidebarOpen = false
    state.cartPopupOpenMobile = false
    state.cartPopupOpenDesktop = false
    state.productType = null
    state.overlayIndex = 0
  },

  searchAutocompleteResult (state, result) {
    state.searchAutocompleteResult = result
  },

  isMobile (state, value: boolean) {
    state.isMobile = value
  },
}

export const actions: ActionTree<RootState['ui'], RootState> = {
  ...UI.actions,
  setProduct ({ commit }, payload) {
    commit('product', payload)
  },
  setSearchAutocompleteResult ({ commit }, result) {
    commit('searchAutocompleteResult', result)
  },
  showCountrySelectorPopup ({ commit }) {
    commit('showCountrySelectorPopup')
  },
  hideCountrySelectorPopup ({ commit }) {
    commit('hideCountrySelectorPopup')
  },
  showMobileSidebar ({ commit }) {
    commit('showMobileSidebar')
  },
  hideMobileSidebar ({ commit }) {
    commit('hideMobileSidebar')
  },
  showDesktopSubmenu ({ commit }) {
    commit('showDesktopSubmenu')
  },
  hideDesktopSubmenu ({ commit, state }) {
    if (state.desktopSubmenuOpen) {
      commit('hideDesktopSubmenu')
    }
  },
  showAddToCartMobile ({ commit }, payload) {
    commit('showAddToCartMobile', payload)
  },
  hideAddToCartMobile ({ commit }) {
    commit('hideAddToCartMobile')
  },
  showAddToCartDesktop ({ commit }, payload) {
    commit('showAddToCartDesktop', payload)
  },
  hideAddToCartDesktop ({ commit }) {
    commit('hideAddToCartDesktop')
  },
  toggleMiniCart ({ commit, state }) {
    if (!state.miniCartOpen) {
      commit('showMiniCart')
    } else {
      commit('hideMiniCart')
    }
  },
  hideMiniCart ({ commit }) {
    commit('hideMiniCart')
  },
  showMiniCart ({ commit }) {
    commit('showMiniCart')
  },
  showCartPopupDesktop ({ commit }, payload) {
    commit('showCartPopupDesktop', payload)
  },
  hideCartPopupDesktop ({ commit }) {
    commit('hideCartPopupDesktop')
  },
  showCartPopupMobile ({ commit }, payload) {
    commit('showCartPopupMobile', payload)
  },
  hideCartPopupMobile ({ commit }) {
    commit('hideCartPopupMobile')
  },
  toggleSearch ({ commit, state }) {
    if (!state.searchOpen) {
      commit('showSearch')
    } else {
      commit('hideSearch')
    }
  },
  hideSearch ({ commit }) {
    commit('hideSearch')
  },
  showMobileProductFilter ({ commit }, payload) {
    commit('showMobileProductFilter', payload)
  },
  hideMobileProductFilter ({ commit }) {
    commit('hideMobileProductFilter')
  },
  showfilterAccordionBlock ({ commit }, payload) {
    commit('showfilterAccordionBlock', payload)
  },
  hidefilterAccordionBlock ({ commit }) {
    commit('hidefilterAccordionBlock')
  },
  showSizeSelectorMobile ({ commit }, payload) {
    commit('showSizeSelectorMobile', payload)
  },
  hideSizeSelectorMobile ({ commit }) {
    commit('hideSizeSelectorMobile')
  },
  showSizeSelectorDesktop ({ commit }, payload) {
    commit('showSizeSelectorDesktop', payload)
  },
  hideSizeSelectorDesktop ({ commit }) {
    commit('hideSizeSelectorDesktop')
  },
  showSizeSelectorPopupMobile ({ commit }, payload) {
    commit('showSizeSelectorPopupMobile', payload)
  },
  hideSizeSelectorPopupMobile ({ commit }) {
    commit('hideSizeSelectorPopupMobile')
  },
  showSizeSelectorPopupDesktop ({ commit }, payload) {
    commit('showSizeSelectorPopupDesktop', payload)
  },
  hideSizeSelectorPopupDesktop ({ commit }) {
    commit('hideSizeSelectorPopupDesktop')
  },
  showMatchingSwimwearSizeSelectorPopupMobile ({ commit }, payload) {
    commit('showSizeSelectorPopupMobile', payload)
  },
  showMatchingSwimwearSizeSelectorPopupDesktop ({ commit }, payload) {
    commit('showSizeSelectorPopupDesktop', payload)
  },
  showProductRestockSidebar ({ commit }, itemId) {
    commit('showProductRestockSidebar', itemId)
  },
  hideProductRestockSidebar ({ commit }) {
    commit('hideProductRestockSidebar')
  },
  showWashingInstructions ({ commit }) {
    commit('showWashingInstructions')
  },
  showSizeGuide ({ commit }, payload) {
    commit('showSizeGuide', payload)
  },
  selectedProductSize ({ commit }, payload) {
    commit('selectedProductSize', payload)
  },
  selectedProductSizeMatching ({ commit }, payload) {
    commit('selectedProductSizeMatching', payload)
  },
  showProductReviewsSidebar ({ commit }, product) {
    commit('showProductReviewsSidebar', product)
  },
  hideProductReviewsSidebar ({ commit }) {
    commit('hideProductReviewsSidebar')
  },
  showProductQuestionsSidebar ({ commit }, product) {
    commit('showProductQuestionsSidebar', product)
  },
  hideProductQuestionsSidebar ({ commit }) {
    commit('hideProductQuestionsSidebar')
  },
  addToCartLoadingMatchingProducts ({ commit }, payload) {
    commit('addToCartLoadingMatchingProducts', payload)
  },
  addToCartLoadingMatchingSwimwearProducts ({ commit }, payload) {
    commit('addToCartLoadingMatchingSwimwearProducts', payload)
  },
  lastClickedQueryID ({ commit }, payload) {
    commit('lastClickedQueryID', payload)
  },
  freeShippingEnabled ({ commit }, payload) {
    commit('freeShippingEnabled', payload)
  },
  closeAll ({ commit }) {
    commit('closeAll')
  },
}
